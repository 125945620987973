'use strict';

var $ = require('../internals/export');
var $some = require('../internals/array-iteration').some;
var sloppyArrayMethod = require('../internals/sloppy-array-method');

// `Array.prototype.some` method
// https://tc39.github.io/ecma262/#sec-array.prototype.some
$({
  target: 'Array',
  proto: true,
  forced: sloppyArrayMethod('some')
}, {
  some: function some(callbackfn /* , thisArg */) {
    return $some(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
  }
});